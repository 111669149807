/// <reference path="../../../models/addtab.ts" />
///<reference path="../../../../Scripts/typings/jquery/jquery.mark.d.ts"/>
///<reference path="../../../scripts/definitions/jqueryui.extensions.d.ts" />
///<reference path="../../../Scripts/Definitions/Framework.d.ts"/>
interface IBreadCrumbsId
{
	id: string;
	display: string;
}

class Breadcrumbs
{
	static moveTabToParentSidebar(tabManagerSelector: string, relatieInstanceId: string)
	{
		const $tabManager = $(tabManagerSelector);
		const allBreadcrumbContainers = $tabManager.find(".breadcrumbs");
		const allBreadcrumbContainersOfRelatie = Breadcrumbs.filterBreadcrumbContainers(allBreadcrumbContainers, relatieInstanceId);

		for (let breadcrumbContainer of allBreadcrumbContainersOfRelatie)
		{
			var ids = new Array();

			$(breadcrumbContainer).find("a").each(function()
			{
				ids.push($(this).data("instanceid"));
			});

			ids.reverse(); // schade->polis->pakket->relatie

			for (let id of ids)
			{
				if ($tabManager.length > 0)
				{
					const sidebarOfParent = this.findParentSidebar($tabManager, id);

					if (sidebarOfParent.length !== 0)
					{
						this.moveAddedTabToSidebar($tabManager, sidebarOfParent, id);
					}
				}
			}
		}
	}

	static removeAllRelatedTabs(instanceId: string)
	{
		const tabManager = $(".tabmanager_tabManager1");

		tabManager.find(`#TAB_${instanceId} .tabsidebar li`).each(function()
		{
			const childInstanceId = $(this).data("instanceid");

			if (childInstanceId != null)
			{
				Breadcrumbs.removeAllRelatedTabs(childInstanceId);
				Breadcrumbs.removeTabFromSidebar(childInstanceId);
			}
		});
	}

	static removeTabFromSidebar(instanceId: string)
	{
		const tabPageCloseButton = $(`li.tabpage[data-instanceid=${instanceId}] .close`);
		const sidebarContainer = $(`.sidebarcontainer li[data-instanceid='${instanceId}']`).closest(".sidebarcontainer");

		tabPageCloseButton.trigger("click");
		$(`li[data-instanceid='${instanceId}']`, sidebarContainer).remove();

		const tabManager = $(sidebarContainer.data("tabmanager"));
		$(tabManager).find("> ul").eq(0).tabs("updatesidebar", sidebarContainer);
	}

	static activateLowestDataTabPage(instanceId: string)
	{
		const tabManager = $(".tabmanager_tabManager1");

		tabManager.find(`#TAB_${instanceId} .breadcrumb a`).each(function()
		{
			const instanceIdOfTab = $(this).data("instanceid");

			if (instanceIdOfTab.length !== 0)
			{
				$(`li.tabpage[data-instanceid='${instanceIdOfTab}']`).addClass("ui-state-active");

				return false;
			}

			return true;
		});
	}

	static activateTabIfExists(instanceId: string)
	{
		if (!instanceId || instanceId === "null" || instanceId === "undefined")
		{
			return false;
		}

		const tabPage = $(`li.tabpage[data-instanceid=${instanceId}] > a`);

		if (tabPage.length === 0)
		{
			return false;
		}

		tabPage.trigger("click");
		Breadcrumbs.activateLowestDataTabPage(instanceId);

		return true;
	};

	static openRelatie(workflowId: string, instanceId: string)
	{
		if (!Breadcrumbs.activateTabIfExists(instanceId))
		{
			Breadcrumbs.raiseWorkflowEvent(workflowId, "OpenRelatie", (response: string) => { handleRunCode(response); });
		}
	}

	static openPakket(workflowId: string, instanceId: string)
	{
		if (!Breadcrumbs.activateTabIfExists(instanceId))
		{
			Breadcrumbs.raiseWorkflowEvent(workflowId, "OpenPakket", (response: string) => { handleRunCode(response); });
		}
	}

	static openPolis(workflowId: string, instanceId: string)
	{
		if (!Breadcrumbs.activateTabIfExists(instanceId))
		{
			Breadcrumbs.raiseWorkflowEvent(workflowId, "OpenPolis", (response: string) => { handleRunCode(response); });
		}
	}

	static raiseWorkflowEvent(workflowId: string, eventName: string, callback: Function)
	{
		$.workflowEvent(
			{
				workflowID: workflowId,
				synchrone: true,
				callback: callback,
				eventName: eventName
			}
		);
	}

	static createSidebarLink(addedTabPage: JQuery, id: string)
	{
		const $item = $(`<li data-addedbybreadcrumb="1" data-instanceid="${id}"><a class="item">${addedTabPage.html()}</a></li>`);

		$item.click(() =>
			{
				Breadcrumbs.activateTabIfExists(id);
			});

		const closeButton = $item.find(".close");
		closeButton.data("instanceid", id);

		closeButton.click(function()
		{
			Breadcrumbs.removeTabFromSidebar($(this).data("instanceid"));
		});

		return $item;
	}

	static findParentSidebar(mainTabManager: JQuery, id: string): JQuery
	{
		const breadcrumb = $(`#TAB_${id}`).find(`a[data-instanceid="${id}"]`).parent();
		let parentBreadcrumb = breadcrumb.prev().find("a");
		let parentInstanceId = parentBreadcrumb.data("instanceid");
		let sidebarOfParent = mainTabManager.find(`ul[data-instanceid="${parentInstanceId}"]`);

		while (parentBreadcrumb.length !== 0)
		{
			if (sidebarOfParent.length > 0)
			{
				return sidebarOfParent;
			}
			else
			{
				parentBreadcrumb = parentBreadcrumb.parent().prev().find("a");
				parentInstanceId = parentBreadcrumb.data("instanceid");
				sidebarOfParent = mainTabManager.find(`ul[data-instanceid="${parentInstanceId}"]`);
			}
		}

		return sidebarOfParent;
	}

	static filterBreadcrumbContainers(arrayOfBreadcrumContainers: JQuery, relatieInstanceId: string): Array<JQuery>
	{
		var breadcrumbContainersOfRelatie = new Array();

		arrayOfBreadcrumContainers.each(function()
		{
			if ($(this).find(`a[data-instanceid="${relatieInstanceId}"`).length > 0)
			{
				breadcrumbContainersOfRelatie.push(this);
			}
		});

		return breadcrumbContainersOfRelatie;
	}

	static moveAddedTabToSidebar(mainTabManager: JQuery, sidebarOfParent: JQuery, id: string)
	{
		if (sidebarOfParent.find(`li[data-instanceid="${id}"]`).length === 0)
		{
			const addedTabPage = mainTabManager.find(`> ul li[data-instanceid="${id}"] a`);

			//remove sidebar link when it is in another sidebar, like polis at relatie
			mainTabManager.find(`li[data-instanceid="${id}"][data-addedbybreadcrumb="1"]`).remove();

			//create and add link to sidebar of parent
			const $item = this.createSidebarLink(addedTabPage, id);
			sidebarOfParent.append($item);

			//fire updatesidebar method of the tabmanager belonging to the sidebar.
			const tabManager = sidebarOfParent.closest(".bottomcontainer").find(".tabmanager_canvas").eq(0);
			$(tabManager).find("> ul").eq(0).tabs("updatesidebar", sidebarOfParent.parent());

			//hide tabpage on top
			addedTabPage.parent().addClass("tab-hidden");
		}
	}
}

(<any>window).Breadcrumbs = Breadcrumbs;